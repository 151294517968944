@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@font-face {
  font-family: "lorin";
  src: url(../public/assets/fonts/Lorin-Regular.ttf) format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "footlight";
  src: url(../public/assets/fonts/FTLTLT.TTF) format("truetype");
  font-weight: 600;
}
@font-face {
  font-family: "montserrat";
  src: url(../public/assets/fonts/Montserrat-Medium.ttf) format("truetype");
  font-weight: 300;
}

/* Colors */
body.home-login {
  padding-top: 0;
}
.bg-transparentblack {
  background-color: rgba(0, 0, 0, 0.616);
}

body {
  background-color: #071011;
  padding-top: 104px;
 
}
.container-fluid {
  --bs-gutter-x: 0rem;
}
.text-lightwhite {
  color: #999999;
}

.darkgreen {
  background-color: #071011;
}
.bg-chocolate {
  background-color: #1e2324;
}
.cursor-pointer {
  cursor: pointer;
}

.roomaminities{
  background-color: #b08e54;
  border-radius: 50%;
  padding: 3px;
}

p {
  font-family: "lorin";
  margin: 0px;
}

h1 {
  font-family: "footlight";
  font-size: 60px;
}
h2 {
  font-family: "footlight";
}
h3 {
  font-family: "footlight";
}
.display-4 {
  font-family: "footlight";
  font-size: 70px;
}
span {
  font-family: "lorin";
}
.fs-7 {
  font-size: 14px;
}
.fs-8 {
  font-size: 12px;
}
.fs-special {
  font-size: 3.5rem;
}
.loading-spinner {
  height: 600px;
  width: 100%;
}
.text-gold {
  color: #b08e54;
}
.text-green {
  color: #689867;
}
.dark {
  background-color: #292c2c;
}
.linehome {
  width: 60px;
}

/* Popups */
.modal-content {
  background-color: #1e2324 !important;
  color: white !important;
  border: none !important;
  border-radius: 10px !important;
  height: 500px;
}
.modal-header {
  border-bottom: none !important;
}

/* ScrollBar */
.roomscrollbar::-webkit-scrollbar {
  width: 8px;
}

.roomscrollbar::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 100px;
}

.roomscrollbar::-webkit-scrollbar-thumb {
  border-radius: 100px;
  background-color: #b08e54;
  box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
}

.for-two-person{
  font-size: 12px;
}

/* Navbar */
.navbarlogo {
  width: 80px;
  height: 80px;
}
.navbar-toggler {
  border: none;
  outline: none;
  padding: 0;
  cursor: pointer;
  background-color: transparent;
}

.navbar-toggler span {
  display: block;
  width: 24px;
  height: 2px;
  margin-bottom: 5px;
  position: relative;
  background: #fff;
  border: none;
  transition: transform 0.2s ease-in-out;
}

.navbar-toggler.open span:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.navbar-toggler.open span:nth-child(2) {
  opacity: 0;
}

.navbar-toggler.open span:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -5px);
}
.navbarsize {
  padding-left: 6.5rem;
  padding-right: 6.5rem;
}
.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
  color: #b69b6c;
  text-decoration: underline !important; /* Apply underline only to active items */
  text-underline-offset: 15px; /* Position the underline slightly below the text */
  text-decoration-color: #b08e54;
}

.navbar-nav :hover {
  color: #b69b6c;
}
.navbar-circle {
  width: 50px;
  height: 48px;
  background-color: #292C2C; /* Change to your preferred color */
  color: white;
  display: flex;
  align-items: center;
  font-family: 'lorin';
  justify-content: center;
  font-size: 1rem; /* Adjust font size as needed */
  font-weight: bold;
}

.nav {
  z-index: 2;
}
.fixed-top{
  z-index: 999;
}
.bg-blur {
  background-color: rgba(
    7,
    16,
    17,
    0.788
  ); /* Change the alpha value for transparency */
  backdrop-filter: blur(40px); /* Adjust the blur strength */
}

.list {
  color: white;
  font-family: "Lorin", sans-serif;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  text-decoration: none; /* No underline by default */
}

.list-none {
  list-style-type: none;
  padding: 0;
  border-bottom: 1px solid;
  border-bottom-color: white;
}

.list-none:hover {
  color: #b69b6c;
}

.nav-item {
  display: flex;
  justify-content: center;
}

.list-none li a.active {
  text-decoration: underline !important; /* Apply underline only to active items */
  text-underline-offset: 10px; /* Position the underline slightly below the text */
  text-decoration-color: #b08e54; /* Set the underline color */
}


.dropdown-toggle::after {
  display: none;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown_border {
  border: 1px solid #3a3e3e;
  border-color: #3a3e3e;
  border-radius: 10px;
}
.drop-down-li:hover {
  background-color: transparent;
}
.drop-down-li:focus {
  background-color: #3a3e3e;
}

.active-btn {
  background-color: #b69b6c;
  color: #fff; /* Text color for active button */
}

.inactive-btn {
  background-color: #071011;
  color: #fff;
}

.hover-red:hover {
  background-color: #b69b6c; /* Change background color on hover to red */
  color: #fff; /* Text color on hover */
}

option {
  background-color: #1e2324 !important;
  color: white;
}
option:hover {
  background-color: #b08e54 !important;
}

/* Buttons */

.btn-outline-login {
  border: 1px solid #b08e54;
  color: white;
}
.btn-outline-login:hover {
  background-color: #b08e54;
  color: black !important;
}

.btn-searchbar {
  background-color: #b08e54 !important;
  color: black !important;
}
.btn-booked {
  background-color: #acacac6e !important;
  color: black !important;
}
.btn-danger {
  background-color: #dc3545 !important;
  color: white !important;
}
.eg-btn {
  background-color: #b08e54 !important;
  color: black !important;
}
.newsletter {
  border: 1px solid red;
}
.btn-newsletter {
  border: none !important;
}
.btn-newsletter:focus {
  border: none !important;
}
.btn-outline-darkgreen {
  color: white;
}
.btn-gold {
  color: #b08e54;
  background-color: #b08e54;
  border: 1px solid #b08e54;
  border-radius: 5px;
}
.btn-gold:hover {
  background-color: transparent;
  border: 1px solid #f2f2f2;
  color: white !important;
}

.btn-outline-darkgreen:focus {
  border-color: #071011 !important;
}
.cancel {
  font-family: "lorin";
}

/* Cancel Screens */

.step-wizard-list {
  background: none;
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.1);
  color: #fff;
  list-style-type: none;
  border-radius: 10px;
  display: flex;
  justify-content: start;
  padding: 20px 10px;
  position: relative;
  z-index: 10;
}

.step-wizard-item {
  padding: 0 20px;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  min-width: 170px;
  position: relative;
}

.step-wizard-item + .step-wizard-item:after {
  content: "";
  position: absolute;
  left: 0;
  top: 19px;
  background: #b08e54;
  width: 100%;
  height: 2px;
  transform: translateX(-50%);
  z-index: -10;
}

.progress-count {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: 600;
  margin: 0 auto;
  position: relative;
  z-index: 10;
  color: transparent;
}

.progress-count:after {
  content: "";
  height: 40px;
  width: 40px;
  background: #b08e54;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  z-index: -10;
}

.progress-count:before {
  content: "";
  height: 10px;
  width: 20px;
  border-left: 3px solid #fff;
  border-bottom: 3px solid #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -60%) rotate(-45deg);
  transform-origin: center center;
}

.progress-label {
  font-size: 14px;
  font-weight: 600;
  margin-top: 10px;
}

.current-item .progress-count:before,
.current-item ~ .step-wizard-item .progress-count:before {
  display: none;
}

.current-item ~ .step-wizard-item .progress-count:after {
  height: 10px;
  width: 10px;
}

.current-item ~ .step-wizard-item .progress-label {
  opacity: 0.5;
}

.current-item .progress-count:after {
  background: #fff;
  border: 2px solid #b08e54;
}

.current-item .progress-count {
  color: #b08e54;
}

/* Image Changer */
.imagechanger {
  position: relative;
  top: -10rem;
  background-size: cover;
}

.imagelist {
  display: flex;
  justify-content: center;
  position: relative;
  top: -10rem;
  gap: 20px;
}
.imagenavlist {
  color: white;
  text-decoration: none;
  font-family: "footlight";
  font-size: 33px;
  position: relative;
  top: 2px;
}

.imagenavlist {
  color: white;
  text-decoration: none;
  font-size: 18px;
  padding-bottom: 10px;
  gap: 10px;
}

/* Searchbar section */
.homesearchbar {
  position: relative;
  top: -20rem;
}
.broder-right {
  border-right: 1px solid #4f442b;
  padding-right: 40px;
}
.dropdownarrow {
  padding-left: 10px;
  height: 10px;
}
.searchbarborder {
  border: 1px solid #4f442b;
  border-radius: 10px;
  background-color: rgba(
    22,
    21,
    21,
    0.295
  ); /* Change the alpha value for transparency */
  backdrop-filter: blur(40px); /* Adjust the blur strength */
  opacity: 30px;
  margin-left: 5rem;
  margin-right: 5rem;
}
.form-searchbar {
  display: block;
  width: 100%;
  padding: 0.2rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-color: transparent;
  color: white;
  border: none;
  flex-wrap: wrap;
}

/* Calender */
.calsearchbar_img {
  height: 1.9rem;
}
.daterangepicker {
  background-color: #071011 !important;
  border: 1px solid #3a3e3e !important;
  position: relative;
}
.calendar-table {
  border: 1px solid #071011 !important;
  border-radius: 4px;
  background-color: #071011 !important;
  color: white;
}
.daterangepicker .calendar-table .prev span {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}
.daterangepicker .calendar-table .next span,
.daterangepicker .calendar-table .prev span {
  color: #fff;
  border-right: solid white !important;
  border-bottom: solid white !important;
  border-width: 0 2px 2px 0;
  border-radius: 0;
  display: inline-block;
  padding: 3px;
}
.daterangepicker td.off {
  background-color: #071011 !important;
}
.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: #b08e54 !important;
  border-color: transparent;
  color: #fff;
}
.daterangepicker td:hover {
  background-color: #b08e54 !important;
}
.daterangepicker td.in-range {
  background-color: #b08e54 !important;
  border-color: transparent;
  color: #000;
  border-radius: 0;
}
.drp-selected {
  color: white !important;
}
.cancelBtn {
  color: white !important;
}
.daterangepicker .drp-buttons {
  border-top: 1px solid #3a3e3e !important;
}
.daterangepicker .calendar-table .next:hover {
  background-color: #b08e54;
}
.daterangepicker .calendar-table .prev:hover {
  background-color: #b08e54;
}
.applyBtn {
  background-color: #b08e54 !important;
  border: none !important;
}

.max-members {
  font-size: 10px;
  color: #6a6e6e;
  text-align: center;
  padding-top: 5px;
}

.gateway {
  position: relative;
  top: -7rem;
}

/* .dropdown-menu {
  position: absolute;
  top: 100%;
  transform: translate3d(0, 140px, 0) !important;
} */

.calendar-icon {
  color: #b69b6c;
}
.event-calendar-icon {
  width: 25px;
}
.btn {
  background-color: transparent;
}
.form-control::placeholder {
  color: white;
}
.form-control {
  color: white;
  background-color: transparent;
}
.form-control:focus {
  color: white !important ;
  background-color: #1e2324 !important;
  border-color: none !important;
  box-shadow: none !important;
  outline: 0 !important;
}
.css-rmj9x9-control {
  color: white;
}

.alice-carousel__dots-item:not(.__custom).__active {
  background-color: #b08e54 !important;
}
.carouselimages{
  height:"720px !important"
}
/* Tour placees */

/* .tourcard {
  width: 457px;
  height: 687px;
} */
.carousel-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 72px; /* Adjust margin as needed */
}

.arrow-btn {
  background: none;
  border: none;
  padding: 10px 10px;
  cursor: pointer;
  color: #b08e54;
  margin-top: 30px;
}
.arrow-btn.prev {
  position: absolute;
  left: 0px; /* Adjust the left position as needed */
  z-index: 1;
}

.arrow-btn.next {
  position: absolute;
  right: 0px; /* Adjust the right position as needed */
}

.tourimage {
  width: 100%;
  height: 300px;
  border-radius: 15px;
  overflow: hidden;
}
.tourimage1{
  width: 25rem;
  height: 336px;
}

/* Testimonials */
.testimonial_description {
  font-size: 13px;
}
.textimonialdesc {
  width: 80%;
}
.textimonialspan {
  margin-left: 4rem;
}
.testimonial_icon {
  position: relative;
  top: 130px;
  padding-right: 200px;
}
/* Testimonial */
.bg-image {
  /* Add your background image URL here */
  background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url("../public/assets/testimonial1.jpg");
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
}

/* Instafeed */
.instafeedcard {
  width: 97%;
  height: 350px;
}

/* Home Blog Card */
.homeblogcard {
  width: 280px !important;
  height: 414px;
}
.homeblogimage {
  width: 268px;
  height: 300px;
  border-radius: 10px;
}

.bgcard {
  background-color: #071011;
  color: white;
  width: 100%;
  overflow: hidden;
}

.home-blog-background {
  color: #fff;
 width: 98%;
  overflow: hidden;
}

/* Awesome */
.awesome-image {
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("../public/assets/awesome.jpeg");
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  padding-top: 7rem;
  padding-bottom: 7rem;
}

/* Footer */
.footerlist {
  text-decoration: none;
  color: white;
}
.egns-lab {
  color: #b69b6c;
  text-decoration: none;
}
.form-newsletter {
  background-color: #071011;
  border: 1px solid white !important;
  border-radius: 5px;
  width: 80%;
  padding: 5px;
}

.newsletter-btn {
  position: relative;
  right: 20px;
}

/* Bookings */
.bookings {
  position: "relative";
}
.topline {
  border-top: 1px solid #282b2b;
  margin-top: 3rem;
}
.panel-body {
  background-color: #1e2526;
}
.property_icons {
  width: 25px;
}
.dimension {
  background-color: #292c2c;
  font-size: 12px;
}
.amenities-list {
  list-style: none;
  padding: 0;
  font-size: 14px;
}
.tags {
  text-decoration: none;
  border: 1px solid #282b2b;
  border-radius: 15px;
  padding: 5px;
  font-size: 12px;
}
.bookingprice {
  position: relative;
  top: 13px;
  right: 20px;
  width: 7rem;
  border-radius: 4px;
}

.bookingimages {
  width:31%;
  height: 70px;
}
.booked {
  position: relative;
}

.booked-overlay {
  position: absolute;
  top: 47%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.733);
  width: 95%;
  height: 100%;
  margin-top: 8px;
  border-radius: 10px;

  text-align: center;
  z-index: 2;
}
.booked-overlay1 {
  position: absolute;
  top: 38.5%;
  left: 18%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.733);
  width: 26rem;
  height: 336px;
  margin-top: 8px;
  border-radius: 10px;

  text-align: center;
  z-index: 2;
}
.imagegallery {
  width: 47%;
}
/* .booking-rotate{
  transform: rotate(-35deg);
} */

.offcanvas {
  background-color: #071011 !important;
  color: white !important;
  padding-top: 3.5rem;
  
}


.cart-count {
  background-color: #689867;
  clip-path: circle();
  padding: 7px;
  position: relative;
  bottom: 1rem;
  right: 0.5rem;
}

.btn-close {
  --bs-btn-close-color: #fff !important;
}

.otherrooms {
  background-color: #071011;
  color: white;
}
.other-room-carousel-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 72px;
  padding-right: 72px;
}
.otherroomprice {
  position: relative;
  top: 15px;
  right: 10px;
  width: 7rem;
  border-radius: 5px;
}

/* Order Details */
.orderform {
  background-color: #1e2526;
}
.orderforminput {
  width: 90%;
  border: 1px solid #b69b6c;
  border-radius: 5px;
  background-color: #1e2324;

  padding: 0.3rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  color: white;
  line-height: 1.5;
}
.orderforminput :focus {
  color: white !important ;
  border: 1px solid #b69b6c !important;
  background-color: #1e2324 !important;

  box-shadow: none !important;
  outline: 0 !important;
}
.choose-payment {
  margin-left: 6rem;
  margin-right: 6rem;
}
.orderforminput:focus {
  border-color: #b69b6c;
}
.form-check-input:checked {
  background-color: #b69b6c;
  border-color: #b69b6c;
}
.card_details {
  background-color: #1e2526;
}
.payment_card {
  border: 0;
}
.payment_form {
  border: 1px solid #606565;
  border-radius: 5px;
  padding: 0.3rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  width: 100%;
  color: white;
  line-height: 1.5;
}
.payment_form::placeholder {
  color: #4a4f4f;
}
.payment_form1 {
  border: 1px solid #606565;
  border-radius: 5px;
  padding: 0.3rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  width: 100%;
  color: white;
  line-height: 1.5;
}
.payment_form2 {
  border: 1px solid #606565;
  border-radius: 5px;
  padding: 0.3rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  width: 100%;
  height: 118px;
  color: white;
  line-height: 1.5;
}
.payment_form2 :focus {
  color: white !important ;
  background-color: #1e2324 !important;
  border-color: none !important;
  box-shadow: none !important;
  outline: 0 !important;
}
.payment_form1 :focus {
  color: white !important ;
  background-color: #1e2324 !important;
  border-color: none !important;
  box-shadow: none !important;
  outline: 0 !important;
}

.form-group {
  display: grid;
  gap: 10px;
  margin-bottom: 0.8rem;
}
.border-right {
  border-right: 1px solid #b69b6c;
}

/* Product Detail */

.product_detail_icon_bg {
  background-color: #1e2526;
}
.product_detail_increment {
  background-color: #3a3e3e;

}
.map-container {
  height: 350px;
}
.progress {
  height: 5px;
}
.progress-bar {
  background-color: #b69b6c;
}
.line {
  padding-left: 180px;
}

/* Properties */
.properties_image_banner {
  position: relative;
  top: -8rem;
}
.properties_banner {
  /* Add your background image URL here */
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url("https://hatimi.s3.amazonaws.com/frontendpropertyImages/image-(6).jpg");
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 85vh;
  position: relative;
  top: -7.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.events_banner {
  /* Add your background image URL here */
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5)),
    url("https://hatimi.s3.amazonaws.com/frontendpropertyImages/image-(7).jpg");
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 90vh;
  position: relative;
  top: -7.5rem;
  display: flex;
  justify-content: center;
  align-items: end;
}

.daypass_banner {
  /* Add your background image URL here */
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.3)),
    url("https://hatimi.s3.amazonaws.com/frontendpropertyImages/Day-pass+1.jpg");
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 90vh;
  position: relative;
  top: -7.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Blogs */
.blog-card {
  width: 442.71px;
  height: 525px;
}
.Blog-title {
  font-size: 22px;
  line-height: 27px;
}
.blog-description {
  font-size: 15px;
}
.blog-card-img-top {
  width: 370.71px;
  height: 270px;
  object-fit: cover;
}
.blog-card-img-top1 {
  width: 100% !important;
  height: 270px;
  object-fit: cover;
}
.blog-background {
  background-color: #1e2526;
  color: #fff;
  width: 22.5rem;
  overflow: hidden;
}

/* Blog Details */
.blog-detail-page {
  padding-left: 7rem;
  padding-right: 7rem;
}
.blog-detail-image {
  margin-left: 6rem;
  margin-right: 6rem;
  width: 88%;
}
.blog-author {
  border-bottom: 1px solid #1e2324;
  border-top: 1px solid #1e2324;
  padding: 10px;
}

/* Comments */
.comment-title {
  color: white !important;
  font-size: 16px !important;
  font-family: "lorin" !important;
  font-weight: 300 !important;
  display: flex;
  justify-content: end;
}
.form {
  background-color: #1e2324 !important;
  color: white !important;
}
.postComment {
  color: white !important;
  border-bottom: 1px solid rgb(66, 66, 66) !important;
}
.emoji-icon {
  color: white !important;
}
.no-comDiv {
  color: white !important;
  font-size: 16px !important;
  font-family: "lorin" !important;
  font-weight: 300 !important;
}
/* Services */
.services-description {
  padding-top: 0.5rem;
  margin-left: 3rem;
  padding-left: 3rem;
}

/* Events */
.event-searchbar-section {
  position: relative !important;
  top: -13rem !important;
}
.roomseventsearch {
  position: relative !important;
  top: 0 !important;
}

/* Contact Us */
.contact {
  margin-left: 5rem;
  margin-right: 5rem;
  margin-top: 5rem;
}
.contact_form {
  border: 1px solid #606565;
  border-radius: 5px;
  padding: 0.3rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  color: white;
  line-height: 1.5;
}
.contact_form::placeholder {
  color: #4a4f4f;
}
.PhoneInputInput {
  flex: 1 1;
  min-width: 0;
  background-color: transparent !important;
  color: white;
  border: none !important;
}
.PhoneInputInput:focus {
  border: none !important;
}
input .PhoneInput {
  border: none !important;
}
.no-border input:focus {
  outline: none;
  border: none;
  /* Additional styling if needed */
}


/* Preloaders */

/* src/components/Preloader.css */
.preloader{
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 104px;
}

.preloader1{
position: fixed;
background-color: #071011;
opacity: .8;
top: 0;
bottom: 0;
left: 0;
right: 0;

}

/* login page */

.login-container {
  height: 100vh;
}

.login-image-container {
  position: relative;
  overflow: hidden;
}

.login-image {
  height: 100vh;
  object-fit: cover;
  filter: blur(0px);
}
.gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.3));
  z-index: 1;
}
.text-container {
  z-index: 1;
}
.login-logo {
  width: 150px;
  height: 150px;
}

/* My Profile */
.initials-circle {
  width: 250px;
  height: 250px;
  background-color: #1e2324; /* Change to your preferred color */
  color: white;
  display: flex;
  align-items: center;
  font-family: 'poppins';
  justify-content: center;
  font-size: 5rem; /* Adjust font size as needed */
  font-weight: bold;
}

/* IpadPro */
@media (max-width: 1200px){
  .navbar-collapse {
    background: transparent !important;
    
    height: 2vh !important ;
    filter: blur(10%);
  }
  .navbar-nav .nav-link.active {
    color: #b69b6c;
    border-bottom: none !important;
    text-align: center;
}

}

@media (max-width: 992px){
  .navbar-collapse {
    background: transparent !important;
    background-color: #071011 !important;
    height: 100vh !important ;
    filter: blur(10%);
  }
  .navbar-nav .nav-link.active {
    color: #b69b6c;
    border-bottom: none !important;
    text-align: center;
}

}

@media (max-width: 600px) {
  .login-container {
    padding: 0 !important;
    
}



.login-image-container {
  position: relative;
  top:0;
  
}
.gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5));
  z-index: 1;
}

.text-container {
  position: absolute;
  top:120px;
  left:5px;
  text-align: center;
}

.login-logo {
    width: 100px;
    height: 100px;
}
.mobile-text{
  font-size: 30px;
  padding:0;
}



  h1 {
    font-size: 38px;
  }
  h2 {
    font-size: 38px;
  }
  p {
    font-size: 15px;
  }
  span {
    font-size: 12px;
  }
  .navbarlogo {
    width: 75px;
    height: 70px;
  }
  .navbarsize {
    padding-left: 20px;
    padding-right: 20px;
  }
  /* .bg-transparent{
    background-color: #071011 !important;
  } */
  .navbar-collapse {
    background: transparent;
    background-color: #071011 !important;
    height: 100vh !important;
    filter: blur(10%);
  }
  .list {
    text-align: center;
  }
  .navbar-brand {
    width: 75%;
  }
  .navbar-nav .nav-link.active {
    color: #b69b6c;
    border-bottom: 2px solid;

    text-align: center;
  }
  .navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: none;
  }
  .homesearchbar {
    position: relative;
    top: -12.5rem;
  }
  .imagelist {
    position: relative;
    top: -6rem;
  }
  .imagenavlist {
    font-size: 14.7px;
  }
  .list-none {
    display: flex;
    flex-wrap: wrap;
    padding: 0px;
    gap: 0.8rem !important;
  }
  .searchbar-mobile {
    gap: 25px;
  }
  .searchbarborder {
    margin: 0px;
  }
  .blog_card {
    gap: 60px;
  }
  .react-multi-carousel-item {
    width: 500px;
  }
  .gateway {
    position: relative;
    top: -7rem;
  }
  .imagechanger {
    position: relative;
    top: -6.5rem;
  }
  .carousel {
    width: 100% !important;
  }
  .carousel-wrapper {
    margin-top: 25px;
    padding: 5px;
  }
  .alice-carousel__stage-item {
    width: 322px;
  }
  .carouselimages{
    height:"500px"
  }

  .tourimage {
    width: 100%;
  }
  .arrow-btn.prev {
    position: absolute;
    left: -27px; /* Adjust the left position as needed */
    z-index: 1;
    display: none;
  }
  .arrowleft {
    font-size: 2.5rem !important;
  }

  .arrow-btn.next {
    position: absolute;
    right: -8px; /* Adjust the right position as needed */
    display: none !important;
  }
  .arrowright {
    font-size: 2.5rem !important;
  }
  .mobileresponsive {
    margin: 0px !important;
    padding: 10px !important;
  }
  .mobilewidth{
    width: 100% !important;
  }
  .blog-background {
    background-color: #1e2526;
    color: #fff;
    width: 22rem;
    overflow: hidden;
  }
  .textimonialdesc {
    width: 100%;
  }
  .mobilepaddingmargin{
    padding-left: 0px !important;
    padding-right: 0PX !important;
  }
  .col-6 {
    width: 100%;
  }
  .booking_amenities {
    margin-top: 20px;
  }
  .form-searchbar {
    font-size: 16px !important;
  }

  .bookingimages {
    width:100px !important;
    height: 70px !important;
  }

  .top-fix-cart{
    margin: 0px !important;
    padding: 10px !important;
  }
  /* Banners */

  /* Other Rooms */
  .other-room-carousel-wrapper {
    padding: 0px;
  }

  .product_meta {
    padding-left: 10px;
  }
  /* product Details */
  .icon-row {
    display: flex !important;
    flex-direction: column;

    align-items: center;
    gap: 10px;
  }
  .bookingcard {
    padding: 0 !important;
    padding-bottom: 3rem !important;
  }
  .bookingprice1 {
    display: flex !important;
    justify-content: start !important;
  }
  .topimages {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 3rem !important;
  }
  /* Order Details */
  .rightcardicon {
    display: flex;
    flex-direction: column;
  }
  .choose-payment {
    margin: 0px;
  }
  .booked-overlay {
width: 100%;
  }
  /* Blogs */
  .Blog-title {
    font-size: 18px;
  }
  /* BlogDetail */
  .mobileblogdetail {
    width: 100% !important;
  }

  /* gurest details */
  .payment_form2 {
    border: 1px solid #606565;
    border-radius: 5px;
    padding: 0.3rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    width: 280px;
    height: 118px;
    color: white;
    line-height: 1.5;
  }
  .payment_form1 {
    border: 1px solid #606565;
    border-radius: 5px;
    padding: 0.3rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    width: 280px;
    color: white;
    line-height: 1.5;
  }
  .daypass-heading{
    display: flex;
    flex-direction: column;
    justify-content: start !important;
    align-items: start !important;
  }
  .daypass-how-its-works{
    font-size: 2rem;
  }

  /* Footer */
  .social_icons_footer {
    display: flex;
    justify-content: center;
  }
  .newsletter {
    display: flex;
    justify-content: center;
  }
  .linehome {
    display: none;
  }
  /* Contact Us */
  .contact_responsive {
    width: 90% !important;
    padding: 20px;
  }
}

@media only screen and (max-width: 1024px) {
  .login-container {
    padding: 0 !important;
    
}

.login-image-container {
  position: relative;
  top:0;
  
}
.gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5));
  z-index: 1;
}

.text-container {
  position: absolute;
  top:120px;
  left:5px;
  text-align: center;
}

.login-logo {
    width: 100px;
    height: 100px;
}
.mobile-text{
  font-size: 30px;
  padding:0;
}


  h1 {
    font-size: 38px;
  }
  h2 {
    font-size: 38px;
  }
  p {
    font-size: 15px;
  }
  span {
    font-size: 12px;
  }
  .navbarlogo {
    width: 75px;
    height: 70px;
  }
  .navbarsize {
    padding-left: 20px;
    padding-right: 20px;
  }
  /* .bg-transparent{
    background-color: #071011 !important;
  } */

  .list {
    text-align: center;
  }
  .navbar-brand {
    width: 75%;
  }
  .navbar-nav .nav-link.active {
    color: #b69b6c;
    border-bottom: 2px solid;

    text-align: center;
  }
  .navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: none;
  }
  .homesearchbar {
    position: relative;
    top: -12.5rem;
  }
  .imagelist {
    position: relative;
    top: -6rem;
  }
  .imagenavlist {
    font-size: 14.7px;
  }
  .list-none {
    display: flex;
    flex-wrap: wrap;
    padding: 0px;
    gap: 0.8rem !important;
  }
  .searchbar-mobile {
    gap: 25px;
  }
  .searchbarborder {
    margin: 0px;
  }
  .blog_card {
    gap: 60px;
  }
  .react-multi-carousel-item {
    width: 500px;
  }
  .gateway {
    position: relative;
    top: -7rem;
  }
  .imagechanger {
    position: relative;
    top: -6.5rem;
  }
  .carousel {
    width: 100% !important;
  }
  .carousel-wrapper {
    margin-top: 25px;
    padding: 5px;
  }
  .alice-carousel__stage-item {
    width: 322px;
  }

  .tourimage {
    width: 100%;
  }
  .arrow-btn.prev {
    position: absolute;
    left: -27px; /* Adjust the left position as needed */
    z-index: 1;
    display: none;
  }
  .arrowleft {
    font-size: 2.5rem !important;
  }

  .arrow-btn.next {
    position: absolute;
    right: -8px; /* Adjust the right position as needed */
    display: none !important;
  }
  .arrowright {
    font-size: 2.5rem !important;
  }
  .mobileresponsive {
    margin: 0px !important;
    padding: 10px !important;
  }
  .mobilewidth{
    width: 100% !important;
  }
  .blog-background {
    background-color: #1e2526;
    color: #fff;
    width: 22rem;
    overflow: hidden;
  }
  .textimonialdesc {
    width: 100%;
  }
  .mobilepaddingmargin{
    padding-left: 0px !important;
    padding-right: 0PX !important;
  }
  .col-6 {
    width: 100%;
  }
  .booking_amenities {
    margin-top: 20px;
  }
  .form-searchbar {
    font-size: 16px !important;
  }

  .bookingimages {
    width:197px;
    height: 100px;
  }

  /* Banners */

  /* Other Rooms */
  .other-room-carousel-wrapper {
    padding: 0px;
  }

  .product_meta {
    padding-left: 10px;
  }
  /* product Details */
  .icon-row {
    display: flex !important;
    flex-direction: column;

    align-items: center;
    gap: 10px;
  }
  .bookingcard {
    padding: 0 !important;
    padding-bottom: 3rem !important;
  }
  .bookingprice1 {
    display: flex !important;
    justify-content: start !important;
  }
  .topimages {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 3rem !important;
  }
  /* Order Details */
  .rightcardicon {
    display: flex;
    flex-direction: column;
  }
  .choose-payment {
    margin: 0px;
  }
  /* Blogs */
  .Blog-title {
    font-size: 18px;
  }
  /* BlogDetail */
  .mobileblogdetail {
    width: 100% !important;
  }

  /* gurest details */
  .payment_form2 {
    border: 1px solid #606565;
    border-radius: 5px;
    padding: 0.3rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    width: 280px;
    height: 118px;
    color: white;
    line-height: 1.5;
  }
  .payment_form1 {
    border: 1px solid #606565;
    border-radius: 5px;
    padding: 0.3rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    width: 280px;
    color: white;
    line-height: 1.5;
  }

  /* Footer */
  .social_icons_footer {
    display: flex;
    justify-content: center;
  }
  .newsletter {
    display: flex;
    justify-content: center;
  }
  .linehome {
    display: none;
  }
  /* Contact Us */
  .contact_responsive {
    width: 90% !important;
    padding: 20px;
  }
}
